import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import appReducer from "./app";
import swapReducer from "./swap/reducer";
import userReducer from "./user/reducer";
import blockReducer from "./block";
import transactionsReducer from "./transactions/reducer";

const store = configureStore({
  reducer: {
    app: appReducer,
    swap: swapReducer,
    user: userReducer,
    block: blockReducer,
    transactions: transactionsReducer,
  },
});

/**
 * @see https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
 */
export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
