import Router from "./routes/Router";
import Navbar from "./components/Navbar";

import { StyledHeader, StyledLayout } from "./style";

const App = () => {
  return (
    <StyledLayout>
      <StyledHeader>
        <Navbar />
      </StyledHeader>
      <Router />
    </StyledLayout>
  );
};

export default App;
