import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: 0,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {},
});

const selectApp = (state: any) => state.app.value;

export { appSlice, selectApp };

export default appSlice.reducer;
