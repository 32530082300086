import { ButtonProps } from "antd";
import React, { ReactNode } from "react";
import { StyledButton } from "./style";
export type TextButtonProps = {
  bgColor: string;
  textColor: string;
  scale: string;
  border?: string;
  children?: NonNullable<ReactNode>;
} & ButtonProps;
export const TextButton = (props: TextButtonProps) => {
  return <StyledButton {...props}>{props.children}</StyledButton>;
};
