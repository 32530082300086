import { Button, Card, Col, Image, Layout, Modal, Row, Typography } from "antd";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

const { Content } = Layout;
const { Text } = Typography;

// Common
type CenteredRowStylesType = {
  space?: string;
};
const centeredRowStyles = ({ space = "auto" }: CenteredRowStylesType) => css`
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: ${space};
`;
export const CenteredRow = styled(Row)`
  ${centeredRowStyles}
`;

// Profile
export const ProfileContent = styled(Content)`
  padding: ${(props) => props.theme.spacing.six};
  min-height: calc(100vh - 64px);
  max-width: 1500px;
  width: 100%;
  margin: auto;
  @media screen and (max-width: 767px) {
    padding: ${(props) => props.theme.spacing.three};
  }
`;

// Inventory
export const TabPaneHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const RefreshButton = styled(Button)`
  text-align: center;
  margin-bottom: 16px;
  border-radius: 999px;
`;
export const ItemCount = styled(Text)`
  display: block;
  font-weight: bold;
  margin-bottom: ${(props) => props.theme.spacing.two};
`;

// Wallet Detail
export const BalanceCardWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const BalanceCard = styled(Card)`
  background: ${(props) => props.theme.color.green1};
  border: none;
  border-radius: 20px;
  padding: 0;
  position: relative;
  width: 100%;
  @media screen and (min-width: 768px) {
    &:first-of-type {
      width: 40%;
    }
  }
`;
export const CardTitle = styled.p`
  color: ${(props) => props.theme.color.white};
  margin-bottom: ${(props) => props.theme.spacing.two};
  font-weight: normal;
  font-size: 24px;
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 5px;
`;
export const TokenValue = styled(Col)`
  font-size: ${(props) => props.theme.fontSize.eight};
  font-weight: bold;
  line-height: 40px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const TokenIcon = styled.img`
  width: auto;
  height: ${(props) => props.theme.fontSize.eight};
  margin: 0;
  padding: 0;
  position: absolute;
  top: 20px;
  right: 20px;
`;
export const InfoIcon = styled.img``;
export const WalletAddressCard = styled(Card)`
  background: #04221a;
  border-radius: 12px 12px 0px 0px;
  border: none;
  padding: 20px ${(props) => props.theme.spacing.one};

  p {
    margin-bottom: 0;
  }
  span.label {
    white-space: nowrap;
  }
`;
export const AddressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 15px);
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;
export const WalletAddressValue = styled.span`
  background: #ebebeb;
  border-radius: 50px;
  color: #333333;
  padding: 5px 16px;
  max-width: 600px;
  min-height: 42px;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-wrap: nowrap;
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
  .address {
    width: calc(100% - 170px);
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const CopyAddressButton = styled(Button)`
  margin: 0px;
  border: none;
  padding: 0px;
  color: #7a798a !important;
  flex: 0 0 20px;
`;
export const OwnedMonstaCountWrapper = styled.div`
  @media screen and (max-width: 767px) {
    margin-top: 1.3rem;
    width: 100%;
  }
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  .icon {
    width: 68px;
    height: auto;
  }

  .info {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;

    .label {
      color: #efefef;
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 16px;
    }
    .value {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 32px;
      color: #fcfcfc;
      letter-spacing: -0.03em;
    }
  }
`;
export const SttBalanceCard = styled(Card)`
  background: ${(props) => props.theme.color.green3};
  border: none;
  margin-top: ${(props) => props.theme.spacing.five};
  border-radius: 10px;
`;
export const PageTitle = styled.p`
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-size: ${(props) => props.theme.fontSize.seven};
  margin-bottom: ${(props) => props.theme.spacing.one};
`;
export const StyledQRModal = styled(Modal)`
  top: 60px;
  .ant-modal-content {
    background-color: white;
    border-radius: 20px;
    padding: 16px;
  }
  @media screen and (max-width: 767px) {
    min-width: 100%;
  }
`;
export const LeftColQR = styled(Col)`
  padding-top: 20px;
  padding-bottom: 80px;
  .ant-typography {
    font-family: Inter;
    font-weight: 600;
    padding-left: 20px;
    color: black;
    font-size: ${(props) => props.theme.fontSize.seven};
    @media (max-width: 767px) {
      line-height: 50px;
      padding-left: 0;
    }
  }
  @media (max-width: 767px) {
    padding-bottom: 50px;
    text-align: center;
    .ant-image {
      margin-top: 20px;
    }
  }
`;
export const RightColQR = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .qr-svg-wrapper {
    margin: 1.5rem 0;
    padding: 0.5rem;
    border: 5px solid black;
  }
  @media (min-width: 768px) {
    border-left: 2px dashed black;
    padding: 0 60px !important;
  }
  @media (max-width: 767px) {
    margin: 0 auto;
  }
`;

// Inception Egg
export const MonstaCardWrapper = styled(Row)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  gap: 20px !important;
  justify-items: center;
  @media (max-width: 600px) {
    justify-content: center;
  }
`;
export const MonstaLoadMoreButtonWrapper = styled.div`
  text-align: center;
  margin-top: 30px;
  margin-bottom: 20px;
`;

export const BorderedCol = styled(Col)`
  border: 4px solid var(--card-dark-green);
  border-radius: 10px;
  padding: 8px;
  background: var(--card-light-green);
  width: 180px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover {
    background-color: var(--card-dark-green);
  }
`;

export const EggImage = styled(Image)`
  width: 100px;
`;

export const InceptionText = styled(Text)`
  font-size: 16px;
  margin-top: 16px;
`;

// MonstaPreviewCard
export const MonstaPreviewCardCol = styled(Col)`
  background-color: ${({ theme }) => theme.color.green1};
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  max-width: 330px;
  position: relative;

  &:hover {
    opacity: 0.6;
  }
`;
export const MonstaPreviewCardTagWrapper = styled(Row)`
  display: flex;
  justify-content: space-between;
  & .ant-tooltip-inner {
    color: black;
    font-weight: bold;
    font-size: 0.75rem;
    min-height: 0.8px;
    padding: 2px 8px;
    border-radius: 10px;
  }
  & .ant-image:first-of-type {
    top: -5px;
    left: -5px;
  }
  & .ant-image:last-of-type {
    top: -5px;
    right: -5px;
  }
`;
export const MonstaPreviewCardTopLeftTagImage = styled(Image)`
  width: 40px;
  height: 40px;
  background-color: var(--card-dark-green);
  padding: 8px;
  border-radius: 10px 0 10px 0;
`;
export const MonstaPreviewCardTopRightTagImage = styled(Image)`
  width: 40px;
  height: 40px;
  background-color: var(--card-dark-green);
  padding: 8px;
  border-radius: 0 10px 0 10px;
`;
type MonstaPreviewCardMonstaImageProps = {
  adult?: boolean;
};
export const MonstaPreviewCardMonstaImage = styled(Image, {
  shouldForwardProp: (props) => props !== "adult",
})<MonstaPreviewCardMonstaImageProps>`
  width: 230px;
  background-color: #172924;
  border-radius: 20px;
  padding: ${({ adult }) => (!adult ? "39px 60px" : "10px 16px")};
`;
export const MonstaPreviewCardIDCard = styled(Text)`
  background-color: #ffbd0c;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  text-transform: capitalize;
  border-radius: 999px;
  color: black;
  padding: 5px 25px;
  position: absolute;
  left: 30px;
  top: 30px;
`;

export const CardIcon = styled.img`
  height: 1.5rem;
  position: absolute;
  right: 30px;
  top: 30px;
`;

// Monsta tag
type MonstaTagProps = {
  color: string;
  size: string;
};
const dynamicStyleMonstaTagText = ({ color, size }: MonstaTagProps) => css`
  color: var(${color});
  text-transform: uppercase;
  border: 1px solid var(${color});
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 0px 5px;
  margin-bottom: 4px;
  margin-right: 4px;
  /* margin-left: 4px; */
  font-size: ${size};
`;
export const MonstaTagText = styled(Text)`
  ${dynamicStyleMonstaTagText};
`;

export const InventoryWrapper = styled.div`
  & .ant-tabs-nav-wrap {
    background-color: #11644f;
    border-radius: 0px 0px 20px 20px;
  }
  & .ant-space-item {
    width: 150px;
    display: flex;
    justify-content: center;
    padding: 10px 0px;
  }
`;

export const MonstaTypeLabel = styled.span`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 33px;
  text-transform: uppercase;
  color: #ffffff;
  margin: 12px auto;
`;

export const ClonedStatus = styled.span`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  text-transform: uppercase;
  color: #ffffff;
  margin: 10px auto;
`;

export const Divider = styled.div`
  width: 100%;
  border: 1px solid #14141f;
`;
