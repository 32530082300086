export const msToTime = (s: number) => {
  s = s / 1000;
  var secs = s % 60;
  s = (s - secs) / 60;
  var mins = s % 60;
  var hrs = (s - mins) / 60;

  var phours = hrs < 10 ? "0" + hrs : hrs;
  var pminutes = mins < 10 ? "0" + mins : mins;
  var pseconds = secs < 10 ? "0" + secs : secs;

  return phours + ":" + pminutes + ":" + pseconds;
};
export const secondToTime = (s: number) => {
  var secs = s % 60;
  s = (s - secs) / 60;
  var mins = s % 60;
  var hrs = (s - mins) / 60;

  var phours = hrs < 10 ? "0" + hrs : hrs;
  var pminutes = mins < 10 ? "0" + mins : mins;
  var pseconds = secs < 10 ? "0" + secs : secs;

  return phours + ":" + pminutes + ":" + pseconds;
};

export const timeConverter = (timestamp: number) => {
  var a = new Date(timestamp * 1000);
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();
  var time =
    date + " " + month + " " + year + " " + hour + ":" + min + ":" + sec;
  return time;
};

// for address that starts with "monsta:"
export const formatAddress = (address: string): string => {
  return `${address.slice(0, 20)}...${address.slice(43, 47)}`;
};
