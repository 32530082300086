import { css } from "@emotion/react";
import styled from "@emotion/styled";

const headingSizeScales = {
  SM: "sm",
  MD: "md",
  LG: "lg",
};
type headingSizeVariantTypes = {
  [size: string]: {
    fontSize?: string;
    fontWeight?: string;
  };
};
const headingSizeVariant: headingSizeVariantTypes = {
  [headingSizeScales.SM]: {
    fontSize: "24px",
    fontWeight: "normal",
  },
  [headingSizeScales.MD]: {
    fontSize: "32px",
    fontWeight: "normal",
  },
  [headingSizeScales.LG]: {
    fontSize: "40px",
    fontWeight: "normal",
  },
} as const;
type HeadingProps = {
  scale?: string;
  size?: string;
  weight?: string;
  color?: string;
};
const headingSizeVariantSelector = (scale: string) => headingSizeVariant[scale];
const dynamicHeadingSizeVariantSelector = ({
  scale = "lg",
  size,
  weight,
  color = "white",
}: HeadingProps) => css`
  font-family: "Molot", sans-serif;
  ${scale && headingSizeVariantSelector(scale)}
  font-size: ${size && size};
  font-weight: ${weight && weight};
  color: ${color};
`;
export const StyledHeader = styled.h2`
  ${dynamicHeadingSizeVariantSelector}
`;
