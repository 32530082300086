import { Web3ReactProvider } from "@web3-react/core";
import { RefreshProvider } from "./contexts/RefreshContext";
import { ThemeProvider } from "@emotion/react";
import { HelmetProvider } from "react-helmet-async";
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import { Provider } from "react-redux";

import { getLibrary } from "./services/web3";
import theme from "./theme";
import store from "./services/redux";

const GOOGLE_TAG_MANAGER_ID = "GTM-5CX46LC";

const Providers = (props: any) => {
  const { children } = props;
  const gtmParams = { id: GOOGLE_TAG_MANAGER_ID };

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <RefreshProvider>
        <ThemeProvider theme={theme}>
          <HelmetProvider>
            <GTMProvider state={gtmParams}>
              <Provider store={store}>{children}</Provider>
            </GTMProvider>
          </HelmetProvider>
        </ThemeProvider>
      </RefreshProvider>
    </Web3ReactProvider>
  );
};

export default Providers;
