const theme = {
  color: {
    white: "#ffffff",
    gray1: "#202020",
    green1: "#11644F",
    green2: "#0D4134",
    green3: "#253933",
    yellow1: "#FEE615",
  },
  fontSize: {
    one: "12px",
    two: "14px", // base
    three: "16px",
    four: "20px",
    five: "24px",
    six: "30px",
    seven: "38px",
    eight: "48px",
  },
  spacing: {
    one: "12px",
    two: "14px",
    three: "16px",
    four: "20px",
    five: "24px",
    six: "30px",
    seven: "38px",
  },
  fontFamily: {
    primary:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
    secondary: '"Molot", sans-serif',
    tertiary: '"Obelix Pro", sans serif',
    fourth: '"Poppins", sans serif',
  },
};

export default theme;
