import { useState } from "react";
import { Row, Col, message, Button, Tooltip } from "antd";
import { CopyOutlined, QrcodeOutlined } from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { formatToReadibleNumber } from "../../../utils/formatBalance";
import { formatAddress } from "../../../utils/common";

import coinIcon from "../../../assets/images/coin-icon.png";
import xsttIcon from "../../../assets/images/xstt-icon.svg";
import monstaIcon from "../../../assets/images/monsta-icon-full.svg";
import infoIcon from "../../../assets/images/info.svg";

import Loading from "../../../components/Loading/Loading";
import GameLoginModal from "./GameLoginModal";
import {
  AddressWrapper,
  CardTitle,
  CopyAddressButton,
  BalanceCard,
  TokenIcon,
  TokenValue,
  WalletAddressCard,
  WalletAddressValue,
  BalanceCardWrapper,
  InfoIcon,
  OwnedMonstaCountWrapper,
} from "../style";
import BigNumber from "bignumber.js";
import { XMONI_TOOLTIP, XSTT_TOOLTIP } from "../constants";

type WalletDetailProps = {
  account: string | null | undefined;
  accountQueryLoading: boolean;
  moniBalance: BigNumber;
  sttBalance: BigNumber;
  ownedMonstas: number;
  listedMonstas: number;
  inceptionEgg: number;
};

const WalletDetail = ({
  account,
  accountQueryLoading,
  moniBalance,
  sttBalance,
  ownedMonstas,
  listedMonstas,
  inceptionEgg,
}: WalletDetailProps) => {
  const [loginModalVisible, setLoginModalvisible] = useState(false);

  const handleCopy = () => {
    message.info("Wallet Address copied to clipboard");
  };

  return (
    <>
      <BalanceCardWrapper>
        {/* <BalanceCard>
          <CardTitle>
            xMONI
            <Tooltip placement="rightBottom" title={XMONI_TOOLTIP}>
              <InfoIcon src={infoIcon} />
            </Tooltip>
          </CardTitle>
          <Row justify="space-between" align="middle">
            <TokenValue>
              {accountQueryLoading ? (
                <Loading />
              ) : (
                formatToReadibleNumber(
                  `${Math.trunc(moniBalance.toNumber() / 1e8) / 1e10}`
                )
              )}
            </TokenValue>
            <TokenIcon src={coinIcon} alt="xMONI icon" />
          </Row>
        </BalanceCard> */}
        {/* <BalanceCard>
          <CardTitle>
            xSTT
            <Tooltip placement="rightBottom" title={XSTT_TOOLTIP}>
              <InfoIcon src={infoIcon} />
            </Tooltip>
          </CardTitle>
          <Row justify="space-between" align="middle">
            <TokenValue>
              {accountQueryLoading ? (
                <Loading />
              ) : (
                formatToReadibleNumber(
                  `${Math.trunc(sttBalance.toNumber() / 1e4) / 1e4}`
                )
              )}
            </TokenValue>
            <TokenIcon src={xsttIcon} alt="xSTT icon" />
          </Row>
        </BalanceCard> */}
      </BalanceCardWrapper>
      <WalletAddressCard size="small">
        <Row align="middle">
          <Col md={12}>
            <AddressWrapper>
              <WalletAddressValue>
                <span>Monsta Wallet Address:</span>
                <span className={account ? "address" : ""}>
                  <strong>
                    {account
                      ? formatAddress(account?.replace("0x", "monsta:"))
                      : "Not connected"}
                  </strong>
                </span>
                {account && (
                  <CopyToClipboard
                    text={account.replace("0x", "monsta:")}
                    onCopy={handleCopy}
                  >
                    <CopyAddressButton
                      ghost={true}
                      color="gray"
                      icon={<CopyOutlined />}
                    />
                  </CopyToClipboard>
                )}
              </WalletAddressValue>
            </AddressWrapper>
            <br />
            <Button
              type="default"
              shape="round"
              icon={<QrcodeOutlined />}
              size="large"
              onClick={() => setLoginModalvisible(true)}
            >
              Game Login
            </Button>
          </Col>
          {/* <Col md={12}>
            <OwnedMonstaCountWrapper>
              <img className="icon" src={monstaIcon} alt="monsta" />
              <div className="info">
                <span className="label">Owned Monsta</span>
                <span className="value">
                  {accountQueryLoading ? (
                    <Loading />
                  ) : (
                    ownedMonstas + listedMonstas + inceptionEgg
                  )}
                </span>
              </div>
            </OwnedMonstaCountWrapper>
          </Col> */}
        </Row>
        <GameLoginModal
          visible={loginModalVisible}
          onCancel={() => setLoginModalvisible(false)}
        />
      </WalletAddressCard>
    </>
  );
};

export default WalletDetail;
