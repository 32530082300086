import { ethers } from "ethers";
import { MonstaConnector } from "./monsta-wallet-connector";

import { config } from "../../config";

const POLLING_INTERVAL = 15000;

export enum ChainId {
  MAINNET = 1001,
  TESTNET = 1000,
}

const BASE_BSC_SCAN_URLS = {
  [ChainId.MAINNET]: "https://explorer.monstachain.org",
  [ChainId.TESTNET]: "https://testnet.monstachain.org",
};

const NetNodes = {
  [ChainId.MAINNET]: ["https://mainnet.monstachain.org"],
  [ChainId.TESTNET]: ["https://testnet.monstachain.org"],
};

const CURRENT_CHAINID =
  parseInt(config.app.REACT_APP_CHAIN_ID || "1000", 10) || 1000;
const CURRENT_NODES = (NetNodes as any)[CURRENT_CHAINID];
export const BASE_BSC_SCAN_URL =
  (BASE_BSC_SCAN_URLS as any)[CURRENT_CHAINID] || "https://example.com";

export const getLibrary = (provider: any): ethers.providers.Web3Provider => {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = POLLING_INTERVAL;
  return library;
};

export const monstaConnector = new MonstaConnector({
  supportedChainIds: [CURRENT_CHAINID],
});

/**
 * Prompt the user to add BSC as a network on Monsta Wallet, or switch to BSC if the wallet is on a different network
 * @returns {boolean} true if the setup succeeded, false otherwise
 */
export const setupNetwork = async () => {
  const provider = (window as any).monsta;
  if (provider) {
    const chainId = CURRENT_CHAINID;
    try {
      await provider.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: `0x${chainId.toString(16)}`,
            chainName: "Monsta Chain Mainnet",
            nativeCurrency: {
              name: "XMONI",
              symbol: "moni",
              decimals: 18,
            },
            rpcUrls: CURRENT_NODES,
            blockExplorerUrls: [`${BASE_BSC_SCAN_URL}/`],
          },
        ],
      });
      return true;
    } catch (error) {
      console.error("Failed to setup the network in Moni:", error);
      return false;
    }
  } else {
    console.error(
      "Can't setup the BSC network on monsta wallet because window.ethereum is undefined"
    );
    return false;
  }
};
