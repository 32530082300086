import { ApolloClient, InMemoryCache } from "@apollo/client";

export const marketplaceClient = new ApolloClient({
  uri: `${process.env.REACT_APP_MARKETPLACE_GRAPHQL}marketplace`,
  cache: new InMemoryCache(),
});

export const erc20Client = new ApolloClient({
  uri: `${process.env.REACT_APP_MARKETPLACE_GRAPHQL}erc20`,
  cache: new InMemoryCache(),
});

export const erc721Client = new ApolloClient({
  uri: `${process.env.REACT_APP_MARKETPLACE_GRAPHQL}erc721`,
  cache: new InMemoryCache(),
});
