import { Layout, Row } from "antd";

import WalletDetail from "./components/WalletDetail";

import { ProfileContent } from "./style";
import Inventory from "./components/Inventory";
import { useQuery } from "@apollo/client";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import { useState, useEffect } from "react";
import { erc20Client } from "../../graphql/apolloClient";
import {
  GetAccountQuery,
  GetAccountQueryVariables,
} from "../../graphql/generated-types";
import { GET_ACCOUNT } from "../../graphql/queries/erc20";
import { BIG_ZERO } from "../../utils/bigNumber";
import { Heading } from "../../components/Heading";

const { Content } = Layout;

const Profile = () => {
  const { account } = useWeb3React();
  const { loading, error, data } = useQuery<
    GetAccountQuery,
    GetAccountQueryVariables
  >(GET_ACCOUNT, {
    variables: {
      id: account?.toLowerCase() || "",
    },
    client: erc20Client,
  });

  const [moniBalance, setMoniBalance] = useState(BIG_ZERO);
  const [sttBalance, setSttBalance] = useState(BIG_ZERO);
  const [imeggBalance, setImeggBalance] = useState(BIG_ZERO);
  const [ownedMonstas, setOwnedMonstas] = useState(0);
  const [listedMonstas, setListedMonstas] = useState(0);

  useEffect(() => {
    if (!account || (data && !data.account)) {
      setMoniBalance(BIG_ZERO);
      setSttBalance(BIG_ZERO);
      setImeggBalance(BIG_ZERO);
      setOwnedMonstas(0);
      setListedMonstas(0);
    }
    let moni = new BigNumber(0);
    let stt = new BigNumber(0);
    let imegg = new BigNumber(0);
    if (data && data.account) {
      const walletData = data.account.ERC20balances;
      walletData.forEach((balance: any) => {
        const value = new BigNumber(balance.valueExact);
        switch (balance.contract.symbol) {
          case "xMONI":
            moni = value;
            break;
          case "STT":
            stt = value;
            break;
          case "xIMEGG":
            imegg = value;
            break;
        }
      });
      setMoniBalance(moni);
      setSttBalance(stt);
      setImeggBalance(imegg);
    }
  }, [data, account]);

  if (account && error) {
    return (
      <Row justify="center" align="middle">
        <p>An unexpected error has occured</p>
      </Row>
    );
  }

  return (
    <Content>
      <Layout>
        <ProfileContent>
          <Heading scale="lg">Profile</Heading>
          <WalletDetail
            account={account}
            accountQueryLoading={loading}
            moniBalance={moniBalance}
            sttBalance={sttBalance}
            ownedMonstas={ownedMonstas}
            listedMonstas={listedMonstas}
            inceptionEgg={imeggBalance.toNumber()}
          />
          {/* <Inventory
            inceptionEgg={imeggBalance.toNumber()}
            setOwnedMonstas={setOwnedMonstas}
            setListedMonstas={setListedMonstas}
          /> */}
        </ProfileContent>
      </Layout>
    </Content>
  );
};

export default Profile;
