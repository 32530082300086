import { Fragment } from "react";
import { Routes, Route } from "react-router-dom";

import Page from "../components/Page";
import Profile from "../views/Profile";
import Marketplace from "../views/Marketplace";
import Monsta from "../views/Monsta";
import HatchView from "../views/Hatch";
import HatchInception from "../views/HatchInception";
import Swap from "../views/Swap";
import Dashboard from "../views/Dashboard";
import TapwarView from "../views/Tapwar";

interface RouteType {
  path: string;
  title: string;
  component: any;
}

const Router = () => {
  return (
    <Fragment>
      <Routes>
        {routes.map((route, i) => (
          <Route
            key={i}
            path={route.path}
            element={<Page title={route.title}>{route.component}</Page>}
          />
        ))}
      </Routes>
    </Fragment>
  );
};

const routes: RouteType[] = [
  {
    path: "/",
    title: "Profile",
    component: <Profile />,
  },
  // {
  //   path: "/swap",
  //   title: "Exchange",
  //   component: <Swap />,
  // },
  // {
  //   path: "/marketplace",
  //   title: "Marketplace",
  //   component: <Marketplace />,
  // },
  // {
  //   path: "/monsta/:monstaId",
  //   title: "Monsta",
  //   component: <Monsta />,
  // },
  // {
  //   path: "/hatch/:tokenId",
  //   title: "Hatch",
  //   component: <HatchView />,
  // },
  // {
  //   path: "/hatch-inception",
  //   title: "Hatch Inception",
  //   component: <HatchInception />,
  // },
  // {
  //   path: "/dashboard",
  //   title: "Dashboard",
  //   component: <Dashboard />,
  // },
  // {
  //   path: "/tapwar",
  //   title: "Tapwar",
  //   component: <TapwarView />,
  // },
];

export default Router;
