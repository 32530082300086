import { gql } from "@apollo/client";

export const GET_ERC20_APPROVAL = gql`
  query GetErc20Approval($id: ID!) {
    erc20Approval(id: $id) {
      valueExact
    }
  }
`;

export const GET_ACCOUNT = gql`
  query GetAccount($id: ID!) {
    account(id: $id) {
      ERC20balances {
        contract {
          symbol
        }
        valueExact
      }
    }
  }
`;
