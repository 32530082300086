export const config = {
  // Core application settings
  app: {
    name: "Monsta Infinite Marketplace",
    origin: process.env.REACT_APP_ORIGIN,
    env: process.env.REACT_APP_ENV,
    REACT_APP_NODE_1: process.env.REACT_APP_NODE_1,
    REACT_APP_CHAIN_ID: process.env.REACT_APP_CHAIN_ID,
    marketplaceGraphql: process.env.REACT_APP_MARKETPLACE_GRAPHQL || "",
    tapwarRest: process.env.REACT_APP_TAPWAR_REST || "",
  },
  api: {
    origin: process.env.REACT_APP_ORIGIN_API,
    prefix: "/", // Cloud Function URL pathname
    path: "/api",
    monsta: process.env.REACT_APP_MONSTA_API,
    aggregate: process.env.REACT_APP_MARKETPLACE_AGGREGATOR_REST,
  },
};
