import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Button } from "antd";
import { TextButtonProps as ButtonProps } from "./TextButton";

enum SizeScales {
  SM = "sm",
  MD = "md",
  LG = "lg",
  FULL = "full",
}
type sizeVariantTypes = {
  [size: string]: {
    height?: string;
    padding?: string;
    width?: string;
    fontSize?: string;
  };
};
const sizeVariant: sizeVariantTypes = {
  [SizeScales.SM]: {
    height: "20px",
    padding: "0 10px",
    fontSize: "0.8rem",
  },
  [SizeScales.MD]: {
    height: "32px",
    padding: "0 16px",
  },
  [SizeScales.LG]: {
    height: "48px",
    padding: "0 24px",
  },
  [SizeScales.FULL]: {
    width: "100%",
    fontSize: "1.2rem",
  },
} as const;
const sizeVariantSelector = (scale: string) => sizeVariant[scale];

const dynamicStyledButton = ({
  bgColor,
  textColor = "#000000",
  scale,
  border,
}: ButtonProps) => css`
  background: ${bgColor};
  border-radius: 999px;
  border: ${border ? border : "0px"};
  color: ${textColor};
  font-size: 1rem;
  height: 45px;
  &:hover {
    background: ${bgColor};
    opacity: 0.8;

    border: ${border ? border : "0px"};
    color: ${textColor};
    border: ${border};
  }
  &:active {
    opacity: 0.9;
    transform: translateY(1px);
    box-shadow: none;
  }
  &:focus {
    background: ${bgColor};

    color: ${textColor};
    border: ${border ? border : "0px"};
  }
  ${sizeVariantSelector(scale)}
`;

export const StyledButton = styled(Button, {
  shouldForwardProp: (props) => props !== "bgColor" && props !== "textColor",
})`
  ${dynamicStyledButton}
`;
