import styled from "@emotion/styled";
import { Layout } from "antd";
const { Header } = Layout;

export const StyledHeader = styled(Header)`
  z-index: 1;
  @media screen and (max-width: 992px) {
    padding: 0 20px;
  }
  background: black;
`;

export const StyledLayout = styled(Layout)`
  height: 100%;

  & > div {
    height: 100%;
  }
`;
